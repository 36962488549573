<template>
  <div class="view pa24">
    <div class="d-flex p-t-20 w100p p-r-31 p-b-17">
      <div
        class="mr-auto p-l-30 fs14 lh30 align-cen"
        style="width: 90%; color: #0b0b0b"
      >
        打卡数据详情
      </div>
    </div>
    <div class="row p-l-30">
      <el-row>
        <el-form label-position="left" label-width="100px">
          <el-form-item label="姓名:">
            {{ form.name }}
          </el-form-item>
          <el-form-item label="电话:">
            {{ form.phone }}
          </el-form-item>
          <el-form-item label="累计打卡天数:">
            {{ form.clockDay }}
          </el-form-item>
          <el-form-item label="首次打卡时间:">
            {{ form.firstTime }}
          </el-form-item>
          <el-form-item label="打卡记录">
            <el-calendar v-model="value" style="width: 700px">
              <template slot="dateCell" slot-scope="{ date, data }">
                <!--自定义内容-->
                <div class="flex-c-c flex-column">
                  <el-button
                    v-if="checkCompareDate(date, data)"
                    @click="calendayClick(date, data)"
                    type="success"
                    circle
                    >{{ data.day.split("-").slice(2).join("-") }}</el-button
                  >
                  <el-button
                    v-else-if="date.getTime() > 0"
                    @click="calendayClick(date, data)"
                    type="info"
                    circle
                    >{{ data.day.split("-").slice(2).join("-") }}</el-button
                  >

                  <div
                    v-if="checkCompareDateNum(date, data) == '2'"
                    style="color: rgba(12, 201, 96, 0.8); margin-bottom: -10px"
                  >
                    ●
                  </div>
                  <div
                    v-else-if="checkCompareDateNum(date, data) == '1'"
                    style="color: rgba(210, 194, 24, 0.8)"
                  >
                    ●
                  </div>
                  <div
                    v-else-if="checkCompareDateNum(date, data) == '0'"
                    style="color: rgba(248, 7, 7, 0.8)"
                  >
                    ●
                  </div>
                </div>
              </template>
            </el-calendar>
          </el-form-item>
          <el-form-item :label="selectRow.createdTime"> </el-form-item>
          <el-form-item label="早安打卡">
            {{ selectRow.amStart }}
          </el-form-item>
          <el-form-item label="晚安打卡">
            {{ selectRow.amEnd }}
          </el-form-item>
        </el-form>
      </el-row>
    </div>
  </div>
</template>

<script>
import { getDataTimeSec } from "@/utils";
import { queryPageUserClockDetail } from "@/api/addedServices";

export default {
  data() {
    return {
      clockId: "",
      form: {},
      value: new Date(),
      selectRow: {
        createdTime: "",
        amStart: "",
        amEnd: "",
      },
    };
  },
  created() {
    this.clockId = this.$route.query.clockId;
    this.searchPage();
  },
  methods: {
    calendayClick(date, data) {
      const day = getDataTimeSec(date, "yy-mm-dd");
      const result = this.form.clockRecords.find((item) => {
        return getDataTimeSec(item.createdTime, "yy-mm-dd") == day;
      });
      if (result) {
        this.selectRow.createdTime = day;
        this.selectRow.amStart = result.amStart
          ? getDataTimeSec(result.amStart, "hh:mm")
          : "";
        this.selectRow.amEnd = result.amEnd
          ? getDataTimeSec(result.amEnd, "hh:mm")
          : "";
      } else {
        this.selectRow.createdTime = day;
        this.selectRow.amStart = "";
        this.selectRow.amEnd = "";
      }
    },
    selectChange() {},
    checkCompareDate(date, data) {
      const day = getDataTimeSec(date, "yy-mm-dd");
      if (this.form.clockRecords) {
        const result = this.form.clockRecords.find((item) => {
          return getDataTimeSec(item.createdTime, "yy-mm-dd") == day;
        });
        return result == null ? false : true;
      }
      return false;
    },
    checkCompareDateNum(date, data) {
      const day = getDataTimeSec(date, "yy-mm-dd");
      if (this.form.clockRecords) {
        const result = this.form.clockRecords.find((item) => {
          return getDataTimeSec(item.createdTime, "yy-mm-dd") == day;
        });
        //2都打卡  1只打卡一次 0 没有打卡
        return result == null
          ? "0"
          : result.amStart && result.amEnd
          ? "2"
          : "1";
      }
      return "0";
    },
    handleClick() {
      this.searchPage();
    },
    searchPage() {
      const data = {
        clockId: this.clockId,
      };
      queryPageUserClockDetail(data)
        .then((res) => {
          if (res) {
            this.form = res.data;
          }
        })
        .catch(function (err) {});
    },
  },
};
</script>

<style lang="scss" scoped>
</style>